import DataAccessLayer from 'common/DataAccessLayer';
import DashState from './DashState.vue';

export default class AdminDataAccessLayer extends DataAccessLayer {
   
    constructor(app) {
        super(app);

        this.profiles = {};
        // this.db = getDatabase();
        this.environment = app.environment;
    }

    get apiServer() {
        return this.environment.config.url.api;
    }

    fetchUserScopes() {
        return this.get(this.apiServer + '/user/scopes');
    }
    addUserScope(scope, uid) {
        return this.put(this.apiServer + `/user/${uid}/scope/${scope}`);
    }
    removeUserScope(scope, uid) {
        return this.delete(this.apiServer + `/user/${uid}/scope/${scope}`);
    }

    fetchUserProfiles(uids) {
        return this.get(this.apiServer + '/user/profiles?uids=' + uids.join(','));
    }

    async loadUserProfiles(uids) {

        // Refresh any missing profiles
        let missing = this.findMissingProfiles(uids);
        if(missing && missing.length > 0) {
            let updates = await this.fetchUserProfiles(missing);
            this.profiles = Object.assign(this.profiles, updates);
        }

        let profiles =  Object.fromEntries(Object.entries(this.profiles).filter(([key]) => uids[key] !==  null));
        return profiles;
    }

    async loadUserProfile(uid) {
        let uids = {};
        uids[uid] = true;
        let profiles = await this.loadUserProfiles(uids);
        return profiles[uid];
    }

    async getUserSummary(uid)  {

        if(uid) {
            return this.get(this.apiServer + `/user/${uid}/summary`);
        }
        
        return this.get(this.apiServer + '/user/summary');
    }

    async getUserIdentity(uid)  {

        return this.get(this.apiServer + `/user/${uid}/identity`);
    }


    async getUserPurchases(uid)  {

        if(uid) {
            return this.get(this.apiServer + `/user/${uid}/purchases`);
        }

        return this.get(this.apiServer + '/user/purchases');
    }

    

    findMissingProfiles(uids) {
        let missing = [];
        for(let uid in uids) {
            if(!this.profiles[uid]) {
                missing.push(uid);
            }
        }
        return missing;
    }



    fetchApiAccounts() {
        return this.get(this.apiServer + '/api/accounts');
    }

    updateApiAccountStatus(name, status) {
        return this.patch(this.apiServer + `/api/account/${name}`, {active: status});
    }

    addApiAccountScope(name, scope) {
        return this.put(this.apiServer + `/api/account/${name}/scope/${scope}`);
    }
    removeApiAccountScope(name, scope) {
        return this.delete(this.apiServer + `/api/account/${name}/scope/${scope}`);
    }

    addApiAccount(name) {
        return this.post(this.apiServer + `/api/account/${name}`);
    }

    deleteApiAccount(name) {
        return this.delete(this.apiServer + `/api/account/${name}`);
    }

    fetchCertExpiry(url) {
        return this.get(this.apiServer + '/info/cert/expiry?url=' + url);
    }

    // fetchProducts(url) {
    //     return this.getJson(this.apiServer + '/billing/products', true);
    // }

    // // fetchPrices(url) {
    // //     return this.getJson(this.apiServer + '/billing/prices', true);
    // // }

    createNewProduct(product) {
        return this.post(this.apiServer + `/billing-api/product`, product);
    }

}
