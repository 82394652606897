export default class Configuration {
    constructor(configuration, options) {
        this.environment = null;
        this.config = {};
        
        this.initialize(configuration, options);
    }

    get name() {
        return this.config.name;
    }

    get product() {
        return this.config.product;
    }

    get firebase() {
        return this.config.firebase;
    }

    initialize(configuration, options) {
        try {
            if (options?.default) {
                this.environment = options.default;
                console.log(`Selected ${this.environment} environment configuration`);
            } else {
                this.environment = this.findDefault(configuration);
                console.log(`Detected ${this.environment} environment configuration`);
            }

            this.config = configuration[this.environment];

            if (options?.override) {
                if (!this.config?.debug) {
                    console.warn(`Applying configuration overrides - for development purposes only!`);
                }
                this.applyOverrides(configuration, options.override);
            }

            if (this.config?.debug) {
                console.log(`${this.config.name} environment loaded`, this.config);
            }
        } catch (error) {
            console.error(`Loading environment configuration`, error);
            throw error;
        }
    }

    findDefault(configuration) {
        const hostname = window.location.hostname;

        let def = null;
        let fallback = null;
        for (let environment in configuration) {
            if (configuration[environment]?.default) {
                def = environment;
            }
            for (let host of configuration[environment]?.hostname) {
                if (hostname.includes(host)) {
                    return environment;
                }
            }

            fallback = fallback || environment; // Fallback
        }

        if (def || fallback) {
            return def || fallback;
        }
        throw new Error(`Detecting default configuration`);
    }

    applyOverrides(config, override, crumb = []) {
        for (const key in override) {
            if (Object.prototype.hasOwnProperty.call(override, key)) {
                const source = override[key];
                const currentCrumb = [...crumb, key]; // Create a copy of the current path
                if (source !== null && typeof source === 'object') {
                    // Recursive call for nested objects
                    this.applyOverrides(config, source, currentCrumb);
                    continue;
                }

                // Retrieve and return the target value from config
                let missing = false;
                let value = source; // Use the source if the env target does not exist
                let target = config[source];
                if (target) {
                    for (const node of currentCrumb) {
                        if (!target[node]) {
                            missing = true;
                            break;
                        }
                        target = target[node];
                    }
                    value = target;
                }
                if (missing) {
                    console.log(`Override missing for ${currentCrumb.join('.')}`);
                    continue;
                }

                // Override the target property value
                let destination = this.config;
                for (let i = 0; i < currentCrumb.length - 1; i++) {
                    const node = currentCrumb[i];
                    if (!destination[node]) {
                        destination[node] = {}; // Ensure intermediate nodes exist
                    }
                    destination = destination[node];
                }

                const property = currentCrumb[currentCrumb.length - 1];
                destination[property] = value;
                console.log(`Override ${currentCrumb.join('-')}: ${value} [${source}]`);
            }
        }
    }

    static get projectId() {
        // Check if we are running on pre-configured Firebase servers
        // let firebase = this.firebaseConfig();
        // if(firebase) { // Check if we are running server-side
        //     return firebase.projectId;
        // }
        if (this.isProduction()) {
            return 'ihunter-d5eab';
        }
        if (this.isDevelopment() || (this.isLocal() && USE_DEV_WHEN_LOCAL)) {
            return 'ihunterdev';
        }
        if (this.isLocal()) {
            return 'localhost';
        }
        return 'localhost';
    }

    static firebaseConfig() {
        return process && process?.env?.FIREBASE_CONFIG && JSON.parse(process.env.FIREBASE_CONFIG);
    }

    static isLocal() {
        return window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1');
    }

    static isDevelopment() {
        return window.location.hostname.includes('dev');
    }

    static isProduction() {
        return !this.isDevelopment() && !this.isLocal();
    }
}
