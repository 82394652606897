import templ8r from '../libs/templ8r';

const METADATA = {
    REGIONS:            '/res/provinces/provinces.json',
    REGION:             '/res/provinces/{region}/province.json',
    RESOURCE:           '/res/provinces/{region}/{resource}/metadata.json',
    RESOURCE_LAYER:     '/res/provinces/{region}/{resource}/{layer}/metadata.json',
    SUBSCRIPTION:       '/res/provinces/{region}/{subscription}/metadata.json',
    SUBSCRIPTION_LAYER: '/res/provinces/{region}/{subscription}/{layer}/metadata.json',
    COUNTIES:           '/tiles/{region}/counties.json',
    COUNTY:             '/tiles/{region}/{county}/metadata.json',
};

const BOUNDARYDATA = {
    RESOURCE_GEOJSON:     '/res/provinces/{region}/{resource}/{layer}/boundarydata.geojson', // aka. /boundaries/{region}/{resource}/{layer}/boundarydata.geojson
    //RESOURCE_MBTILES:     '/res/provinces/{region}/{resource}/{layer}/boundarydata.mbtiles',
    //SUBSCRIPTION_GEOJSON: '/res/provinces/{region}/{subscription}/{layer}/boundarydata.geojson',
   // SUBSCRIPTION_MBTILES: '/res/provinces/{region}/{subscription}/{layer}/boundarydata.mbtiles',
    COUNTY_GEOJSON:       '/tiles/{region}/{county}/boundary.geojson',

    RESOURCE_LAYER:       '/getBoundariesFromDatabase.php?&database=res/provinces/{region}/{resource}/{layer}/boundarydata.db&identifyby=1',
    SUBSCRIPTION_LAYER:   '/getBoundariesFromDatabase.php?&database=res/provinces/{region}/subscription/{layer}/boundarydata.db&identifyby=2'
}


const TILEDATA = {
    COUNTY_RASTER:          '/tiles/{region}/{county}/{z}/{x}/{y}.png',
    RESOURCE_VECTOR:        '/layers/{region}/{resource}/{layer}/boundarydata/{z}/{x}/{y}.pbf',
    RESOURCE_METADATA:      '/layers/{region}/{resource}/{layer}/boundarydata.mbtiles',
}

const APPDATA = {
    MESSAGES:                '/messages/{region}.json'
}

export default class CatalogHostedResources {

    static CopyProperties(properties, metadata, summary) {
        if(!summary) {
            summary = {};
        }
        for(let key in properties) {
            const property = properties[key];
            if(metadata[property]) {
                summary[key] = metadata[property];
            }
        }
        return summary
    }

    static RegionsPath() {
        return METADATA.REGIONS;
    }
    static AllRegions() {
        return this.RegionsPath() + this.cacheBuster();
    }

    static RegionPath(region) {
        return templ8r(METADATA.REGION, {region: region});
    }
    static RegionMetadata(region) {
        return this.RegionPath(region) + this.cacheBuster();
    }

    static ResourcePath(region, resource) {
        return templ8r(METADATA.RESOURCE, {region: region, resource: resource});
    }
    static ResourceMetadata(region, resource) {
        return this.ResourcePath(region, resource) + this.cacheBuster();
    }

    static ResourceLayerPath(region, resource, layer) {
        return templ8r(METADATA.RESOURCE_LAYER, {region: region, resource: resource, layer: layer});
    }
    static ResourceLayerMetadata(region, resource, layer) {
        return this.ResourceLayerPath(region, resource, layer) + this.cacheBuster();
    }
    static ResourceLayerBoundarydata(region, resource, layer) {
        return templ8r(BOUNDARYDATA.RESOURCE_LAYER, {region: region, resource: resource, layer: layer});//  + this.cacheBuster();
    }

    // New style without webserver
    static ResourceLayerBoundaryGeoJson(region, resource, layer) {
        return templ8r(BOUNDARYDATA.RESOURCE_GEOJSON, { region: region, resource: resource, layer: layer });// + this.cacheBuster();
    }

    static SubscriptionPath(region, subscription) {
        if (!subscription || subscription === 'pro') {
            subscription = 'subscription'; // default name for generic region subscription
        }
        return templ8r(METADATA.SUBSCRIPTION, {region, subscription});
    }
    static SubscriptionMetadata(region, subscription) {
        return this.SubscriptionPath(region, subscription) + this.cacheBuster();
    }
    
    static SubscriptionLayerPath(region, subscription, layer) {
        if (!subscription || subscription === 'pro') {
            subscription = 'subscription'; // default name for generic region subscription
        }
        return templ8r(METADATA.SUBSCRIPTION_LAYER, {region, layer, subscription});
    }
    static SubscriptionLayerMetadata(region, subscription, layer) {
        return this.SubscriptionLayerPath(region, subscription, layer) + this.cacheBuster();
    }    
    static SubscriptionLayerBoundarydata(region, layer) {
        return templ8r(BOUNDARYDATA.SUBSCRIPTION_LAYER, { region: region, layer: layer });// + this.cacheBuster();
    }
    // New style without webserver
    static SubscriptionLayerBoundaryGeoJson(region, subscription, layer) {
        return templ8r(BOUNDARYDATA.RESOURCE_GEOJSON, { region: region, resource: subscription, layer: layer });// + this.cacheBuster();
    }

    static CountyPath(region) {
        return templ8r(METADATA.COUNTIES, {region: region});
    }
    static CountyMetadata(region) {
        return this.CountyPath(region) + this.cacheBuster();
    }
    static CountyBoundaryGeoJson(region, county) {
        return templ8r(BOUNDARYDATA.COUNTY_GEOJSON, { region, county });// + this.cacheBuster();
    }


    static CountyProductPath(region, county) {
        return templ8r(METADATA.COUNTY, {region: region, county: county});
    }
    static CountyProductMetadata(region, county) {
        return this.CountyProductPath(region, county) + this.cacheBuster();
    }
    static CountyBoundaryTiles(region, county) {
        return templ8r(TILEDATA.COUNTY_RASTER, { region, county});
    }

    static ResourceLayerTiles(region, resource, layer) {
        return templ8r(TILEDATA.RESOURCE_VECTOR, { region, resource, layer});
    }

    static ResourceLayerTilesetMetadata(region, resource, layer) {
        return templ8r(TILEDATA.RESOURCE_METADATA, { region, resource, layer});
    }

    static AppMessages(region) {
        return templ8r(APPDATA.MESSAGES, { region });
    }

    static cacheBuster() {
        return '?v=' + Date.now();
    }
}