import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged, GoogleAuthProvider, FacebookAuthProvider, EmailAuthProvider } from "firebase/auth";

import Vue from 'vue';
import decode from 'jwt-decode';

import Configuration from './Configuration';

import firebaseConfig from '../../firebase.config.json';
import ihunterConfig from '../../ihunter.config.json';

export default class App {

    constructor(MainVue) {

        this.environment = new Configuration(ihunterConfig, {
            // default: 'dev'
        });

        window.addEventListener('load', () => {

            this.app = this.initializeFirebase();
            this.auth = this.initializeAuth();
            this.vm = this.initializeVue(MainVue);
            
            this.watchRouteChange();
        });

        window.app = this; // Expose ourselves as singleton
    }

    initializeFirebase() {
        console.log(`Connecting to ${this.environment.firebase}`);
        let firebase = initializeApp(firebaseConfig[this.environment.firebase]);
        firebase.automaticDataCollectionEnabled = false;

        let analytics = getAnalytics(firebase);

        return firebase;
    }

    initializeAuth() {
        let auth = getAuth(this.firebase);
        onAuthStateChanged(auth, async (user) => {
            try {
                if(user) {
                    // User is signed in
                    console.log(`User authenticated: ${user.uid}`);

                    this.user = user;
                    let token = user.accessToken;
                    if(!token) {
                        throw new Error("Failed to retreive access token");
                    }
                    

                    let jwt = decode(token);
                    this.vm.authStatusChanged(jwt, user);

                } else {
                    // User is signed out
                    console.log("User signed-out");
                    this.user = null;

                    this.vm.authStatusChanged();
                }
            }catch(error) {
                console.error(error);
                this.vm.errorHandler(`Error requesting access token from authorization server: ${error.message}`, error);
                this.user = null;
            }
        });
        return auth;
    }

    initializeVue(MainVue) {

        console.log("Load vue");
        let div = document.createElement('div');
        div.id = 'main';
        document.body.prepend(div);
        this.vue = new Vue({
            el: '#main',
            render: h => h(MainVue)
        });
        return this.vue.$children[0];
    }

    watchRouteChange() {
        let routeChange = () => {
            const ROUTE_TOKEN = '#/';
            const ARGS_TOKEN = '/';
            const PARAMS_TOKEN = '?';
            let hash = window.location.hash.replace(ROUTE_TOKEN, '');
            let route, args, params;

            let searchIndex = hash.indexOf(PARAMS_TOKEN);
            if(searchIndex > 0) {
                let search = hash.substring(searchIndex);
                params = Object.fromEntries(new URLSearchParams(search));
            }else {
                searchIndex = null;
            }


            let index = hash.indexOf(ARGS_TOKEN);
            if(index > 0) {
                route = hash.substring(0, index);
                if(searchIndex > 0) {
                    args = hash.substring(index + 1, searchIndex);
                }else {
                    args = hash.substring(index + 1);
                }

            }else {
                //route = hash;
                args = null;

                if(searchIndex > 0) {
                    route = hash.substring(0, searchIndex);
                }else {
                    route = hash;
                }
            }

           

            this.vm.routeChanged(route, args?decodeURI(args):null, params);
            
            this.scrollToTop();
        };

        window.addEventListener('hashchange', routeChange); 
        routeChange();
    }

    async getFirebaseToken() {
        return this.user ? await this.user.getIdToken() : null; // Will trigger renewal if token is near expired (5 min)
    }

    scrollToTop() {
        window.scroll(window.scrollX, 0); // Scroll back to the top on route change
    }

    navigate(route, args, params) {
        let url = '';
        if(route && args) {
            url = `#/${route}/${encodeURI(args)}`;
        }else if(route) {
            url = '#/' + route;
        }

        if(params) {
            url += '?' + new URLSearchParams(params).toString();
        }

        this.scrollToTop();
        window.location.assign(url);

    }

    signIn(container) {

        if(this.ui) {
            return this.ui.isPendingRedirect();
        }

        // FirebaseUI config.
        var config = {
            //autoUpgradeAnonymousUsers: true,
            signInFlow: 'popup',
            //signInSuccessUrl: window.location,//'#/success',
            callbacks: {
                signInSuccessWithAuthResult: (result) => {
                    // console.log("Signin success callback: ");
                    // console.log(result);
                    return false;
                },
                signInFailure: (error) => {
                    console.error(error);
                    // For merge conflicts, the error.code will be
                    // 'firebaseui/anonymous-upgrade-merge-conflict'.
                    if (error.code !== 'firebaseui/anonymous-upgrade-merge-conflict') {
                      return Promise.resolve();
                    }
                    // The credential the user tried to sign in with.
                    var cred = error.credential;
                    // Copy data from anonymous user to permanent user and delete anonymous
                    // user.
                    // ...
                    // Finish sign-in after data is copied.
                    return this.auth.signInWithCredential(cred);
                }
            },
            signInOptions: [
                GoogleAuthProvider.PROVIDER_ID,
                'apple.com',
                FacebookAuthProvider.PROVIDER_ID,
                EmailAuthProvider.PROVIDER_ID,
                
            ],
            tosUrl: 'https://www.ihunterapp.com/subscription-terms-of-use/',
            privacyPolicyUrl: 'https://www.ihunterapp.com/privacy/'
        };

        // Initialize the FirebaseUI Widget using Firebase.
        this.ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(this.auth);
        // if(ui.isPendingRedirect()) {
        //     ui.start(container, config);
        // }

        this.ui.start(container, config);
        return this.ui.isPendingRedirect();
    }

    signOut() {
        this.auth.signOut();

        window.location.reload();
    }

    // async sendPasswordResetEmail() {
    //     try {
    //         return await sendPasswordResetEmail(this.auth.currentUser.email);

    //     } catch(error) {
    //         console.error('Password reset: ' + error.message);
    //         throw error;
    //     }
    // }

    // async updateEmail(email) {
    //     try {
    //         return await this.auth.currentUser.updateEmail(email);       
            
    //     } catch (error) {
    //         console.error('Updating email address: ' + error.message);
    //         throw error;
    //     }
    // }

    // async updateUserProfile(name) {
    //     try {
    //         return await this.auth.currentUser.updateProfile({
    //             displayName: name.trim()
    //         });       

    //     } catch (error) {
    //         console.error('Updating profile name: ' + error.message);
    //         throw error;
    //     }
    // }

    // async reauthenticateWithCredential(password) {
    //     try {
    //         const credential = EmailAuthProvider.credential(this.auth.currentUser.email, password);
    //         return await signInWithCredential(this.auth, credential);
            
    //     } catch (error) {
    //         console.error('Verifying credentials: ' + error.message);
    //         throw error;
    //     }
    // }

}