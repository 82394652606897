<style lang="scss" scoped>

.tab {
    max-height: 40vh;
    overflow: auto;
}


</style>

<template>
    <div>
        <h1>Product Catalog</h1>

        <div class="card">
            <h2>Firebase</h2>
            <p><span>Product catalog in Firebase. Used by the Store.</span></p>
            <!-- <h3>Regions</h3> -->
            <tabs>
                <tab v-for="(region, key) in firebaseCatalog" :key="key" :title="region?.short">
                    <catalog-explorer :id="key" :catalog="region" :provider="firebaseProvider"></catalog-explorer>
                </tab>
            </tabs>
        </div>

        <div class="card">
            <h2>Hosted</h2>
            <p><span>Product catalog as hosted in JSON (.json) metadata files on the WebApp and Tile resource servers. Used by iOS, Android and WebApp clients.</span></p>
            <button v-if="empty(hostedCatalog)" @click="loadHostedRegions">Build catalog</button>
            <p v-if="empty(hostedCatalog)"><span>To begin, click <b>Build catalog</b> to iteratively query the top-level <i>provinces.json</i> and subsequent <i>province.json</i> metadata for each region and their associated subscription and layers <i>metadata.json</i> and <i>counties.json</i>. Please be patient - loading the full catalog will request hundereds of files from our servers.</span></p>
            <!-- <button v-else @click="exportHosted">Export catalog</button> -->

            <!-- <h3>Regions</h3> -->
            <tabs>
                <tab v-for="(region, key) in sortedCatalog" :key="key" :title="region?.short">
                    <progress-dots v-if="merging">Merging metadata</progress-dots>
                    <catalog-explorer v-else :id="region?.id" :catalog="region" :provider="hostedProvider" @merge="mergeCatalogMetadata"></catalog-explorer>
                    

                    <!-- <button @click="updateRegion(key)">Merge Region to Firebase</button> 
                    <button @click="updateSubscription(key)">Merge Subscription to Firebase</button>  -->
                </tab>
            </tabs>

            <!-- <div v-if="hostedCatalog"> 
                <h3>Hosted Catalog</h3>
                <json-viewer :value="hostedCatalog" :expand-depth="1" :sort="true"></json-viewer>
            </div>

            <div v-if="firebaseCatalog"> 
                <h3>Firebase Catalog</h3>
                <json-viewer :value="firebaseCatalog" :expand-depth="1" :sort="true"></json-viewer>
            </div> -->


            <!-- <h3 v-if="!empty(hostedMetadata)">Regions</h3> 
            <tabs v-if="!empty(hostedMetadata)">
                <tab v-for="(region, key) in hostedMetadata" :key="key" :title="region.short">

                    <catalog-region-explorer :region="region"></catalog-region-explorer>

                    

                    <button @click="refreshRegion(key)">Compare metadata</button>

                    <p v-if="empty(firebaseMetadata) || !firebaseMetadata[key]">
                        <span>Click <b>Refresh metadata</b> to query and compare the Hosted catalog region to the Firebase catalog region.</span>
                    </p>
                    <p v-else-if="empty(firebaseMetadata[key])">
                        <span>Region does not exist in the Firebase catalog.</span>
                        <button @click="updateRegion(key)">Add region to Firebase</button> 
                    </p>
                    <p v-else-if="equals(region?.metadata, firebaseMetadata[key])">
                        <span>Region metadata <b>matches</b> in both Firebase and Hosted catalogs.</span>
                    </p>
                    <p v-else>
                        <span>Region metadata is <b>out-of-sync</b> betweeen catalogs. Compare differences between the Hosted vs Firebase catalogs.</span>
                        <button @click="updateRegion(key)">Merge changes to Firebase</button> 
                        <json-diff newTitle="Hosted" :newData="region?.metadata" oldTitle="Firebase" :oldData="firebaseMetadata[key]"></json-diff>
                    </p>

                </tab>
            </tabs> -->
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import JsonViewer from 'vue-json-viewer';
    import JsonCompare from 'vue-json-compare';

    import deepEqual from 'deep-equal';
    import {downloadZip} from 'client-zip';

    import CardGrid from './CardGrid.vue';

    import empty from 'libs/empty';

    import CatalogHostedResources from 'common/CatalogHostedResources';
    import CatalogFirebaseProvider from 'common/CatalogFirebaseProvider';
    import CatalogHostedProvider from 'common/CatalogHostedProvider';

    const IHUNTER_PRODUCT = 'ihunter';

    const PRODUCT_REGIONS_MAP = {
        //'ihunter' ; [] // iHunter is a special case and we lookup regions from provinces.json directly
        'repromap': ['rm']
    }

    @Component({
        components: {
            JsonViewer, 
            JsonCompare
        }
    })
    export default class CatalogPage extends CardGrid {

        product = IHUNTER_PRODUCT;


        firebaseProvider = null;
        hostedProvider = null;

        firebaseCatalog = {}
        hostedCatalog = {};

        merging = false;

        get sortedCatalog() {
            return this.orderBy(this.hostedCatalog, 'short');
        }

        mounted() {
            this.firebaseProvider = new CatalogFirebaseProvider(this.product);
            this.firebaseProvider.watchCatalog((region, catalog) => {
                this.$set(this.firebaseCatalog, region, catalog);
            });
        }

        loadHostedRegions() {
            this.hostedProvider = new CatalogHostedProvider(this.product, this.config.url);
            if(this.product == IHUNTER_PRODUCT) {
                this.hostedProvider.watchCatalog((region, catalog) => {
                    this.$set(this.hostedCatalog, region, catalog);
                });
            }else {
                this.hostedProvider.watchRegions(PRODUCT_REGIONS_MAP[this.product], (region, catalog) => {
                    this.$set(this.hostedCatalog, region, catalog);
                });
            }
        } 

        async refreshRegion(region) {
            this.firebaseMetadata[region] = await this.firebaseProvider.getRegionMetadata(region);
            this.$forceUpdate();
        }


        async sleep(time) {
            return new Promise((resolve) => setTimeout(resolve, time));
        }

        async mergeCatalogMetadata(region, metadata) {

            const BACKOFF_DELAY = 250;

            this.merging = true;

            if(metadata.region) {
                await this.firebaseProvider.updateRegionMetadata(region, metadata.region);
            }

            if(metadata.subscriptions) {
                for(let subscription in metadata.subscriptions) {
                    await this.sleep(BACKOFF_DELAY);
                    await this.firebaseProvider.updateSubscriptionMetadata(region, subscription, metadata.subscriptions[subscription]);
                }
            }

            if(metadata.subscriptions_layers) {
                for(let subscription in metadata.subscriptions_layers) {
                    for(let layer in metadata.subscriptions_layers[subscription]) {
                        await this.sleep(BACKOFF_DELAY);
                        await this.firebaseProvider.updateSubscriptionLayerMetadata(region, subscription, layer, metadata.subscriptions_layers[subscription][layer]);
                    }
                }
            }

            if(metadata.resources) {
                for(let resource in metadata.resources) {
                    await this.sleep(BACKOFF_DELAY);
                    await this.firebaseProvider.updateResourceMetadata(region, resource, metadata.resources[resource]);
                }
            }

            if(metadata.resources_layers) {
                for(let resource in metadata.resources_layers) {
                    for(let layer in metadata.resources_layers[resource]) {
                        await this.sleep(BACKOFF_DELAY);
                        await this.firebaseProvider.updateResourceLayerMetadata(region, resource, layer, metadata.resources_layers[resource][layer]);
                    }
                }
            }

            if(metadata.maps) {
                for(let resource in metadata.maps) {
                    for(let layer in metadata.maps[resource]) {
                        for(let map in metadata.maps[resource][layer]) {
                            await this.sleep(BACKOFF_DELAY);
                            await this.firebaseProvider.updateMapMetadata(region, resource, layer, map, metadata.maps[resource][layer][map]);
                        }
                    }
                }
            }

            if(metadata.products) {
                for(let resource in metadata.products) {
                    for(let layer in metadata.products[resource]) {
                        for(let map in metadata.products[resource][layer]) {
                            for(let product in metadata.products[resource][layer][map]) {
                                await this.sleep(BACKOFF_DELAY);
                                await this.firebaseProvider.updateProductMetadata(region, resource, layer, map, product, metadata.products[resource][layer][map][product]);
                            }
                        }
                    }
                }
            }
            
            this.merging = false;
        }

        // async updateRegion(region) {
        //     let metadata = await this.hostedProvider.getRegionMetadata(region);
        //     if(metadata) {
        //         await this.firebaseProvider.updateRegionMetadata(region, metadata);
        //     }
        // }

        // async updateSubscription(region) {
        //     let metadata = await this.hostedProvider.getSubscriptionMetadata(region);
        //     if(metadata) {
        //         await this.firebaseProvider.updateSubscriptionMetadata(region, metadata);
        //     }
        // }
        
        orderBy(object, property) {
            if(!object) {
                return object;
            }
            let array = Object.values(object);
            let sorted = array.sort((a, b) => a[property].localeCompare(b[property]));
            return sorted;
        }

        equals(a, b) {
            return deepEqual(a, b, {strict: true});
        }

        empty(obj) {
            return empty(obj); // == {}
        }

        async exportHosted() {

            const ROOT_FOLDER = this.product + '/';
            const date = new Date();

            let files = [];
            
            // provinces.json
            files.push({ 
                name: ROOT_FOLDER + CatalogHostedResources.RegionsPath(),
                input: JSON.stringify(
                    {
                        provinces: Object.keys(this.hostedMetadata)
                    }
                )
            });
            
            for(let id in this.hostedMetadata) {
                let region = this.hostedMetadata[id];

                // provinces.json
                files.push({ 
                    name: ROOT_FOLDER + CatalogHostedResources.RegionPath(id),
                    input: JSON.stringify(region.metadata)
                });

                // subscription/metadata.json
                files.push({ 
                    name: ROOT_FOLDER + CatalogHostedResources.SubscriptionPath(id),
                    input: JSON.stringify(region.subscription)
                });

                for(let layer in region.layers) {
                    // subscription/{layer}/metadata.json
                    files.push({ 
                        name: ROOT_FOLDER + CatalogHostedResources.LayerPath(id, layer),
                        input: JSON.stringify(region.layers[layer])
                    });
                }
            }

            // get the ZIP stream in a Blob
            const blob = await downloadZip(files).blob();
            
            
            // make and click a temporary link to download the Blob
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `${this.product}_catalog_${date.toISOString()}.zip`;
            link.click();
            link.remove();
        }
    }

    Vue.component('catalog-page', CatalogPage);

</script>

