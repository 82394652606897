<style lang="scss">
    @import './dashboard.scss';
    @import 'styles/colors.scss';

    .dashboard {
        margin: 0;
        padding: 0;
        color: white;
        box-sizing: border-box;
    }

    .login {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 25px;

        img {
            object-fit: none;
            // margin-top: 25px;
        }
    }

    .grid-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: $header-height 1fr $footer-height;

        grid-template-areas:
          'header'
          'main'
          'footer';
        height: 100vh;

        @media only screen and (min-width: $mobile-breakpoint) {
            display: grid;
            grid-template-columns: 185px 1fr;
            grid-template-rows: $header-height 1fr $footer-height;
            grid-template-areas:
                'aside header'
                'aside main'
                'aside footer';
            height: 100vh;
        }
    }

    .menu-toggle {
        font-size: 22px;
        position: absolute;
        display: flex;

        align-items: center;
        justify-content: center;
        z-index: 1;
        cursor: pointer;
        padding: 8px 16px;
        color: #ddd;

        @media only screen and (min-width: $mobile-breakpoint) {
            display: none;
        }
    }

    header {
        grid-area: header;
        margin-left: 30px; // Give space for menu-toggle button
        //height: $header-height;
        //padding: 0 8px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .title {
            display: flex;
            align-items: center;

            img {
                object-fit: contain;
                height: 24px;
                padding: 0 5px;
                cursor: pointer;
            }
            
            span {
                padding: 8px 5px 3px 5px;
                font-size: 18px;
                font-weight: bold;
            }
        }

        @media only screen and (min-width: $mobile-breakpoint) {
            margin-left: 0;
        }
    }

    aside {
        grid-area: aside;
        background-color: $menu-colour;

        font-size: 18px;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 185px;
        position: fixed;
        overflow-y: auto;
        z-index: 2;
        transform: translateX(-190px);
        transition: transform 0.2s ease-out;

        &.active {
            transform: translateX(0);
        }

        @media only screen and (min-width: $mobile-breakpoint) {

            display: flex;
            flex-direction: column;
            position: relative;
            transform: translateX(0);
        }



        .title {            
            span {
                position: relative;
                top: 15px;
                padding: 14px;
                font-size: 22px;
                font-weight: bold;
            }
        }

        a {
            @include hover-underline($mono-white);
        }

        ul {
            margin-top: 40px;
            padding: 20px;

            li {
                padding: 10px 2px;

                @include hover-underline(white);

                transition: text-shadow 0.15s ease-in-out;

                &.active {
                    font-weight: bold;
                    text-shadow: 0 0 10px $mono-glow, 0 0 20px $mono-glow;
                }
            }
        }


    }

    .profile-menu {

        background-color: $menu-colour;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        width: 120px;
        top: $header-height;
        right: 0;
        position: fixed;
        overflow-y: auto;
        z-index: 2;
        transform: translateX(125px);
        transition: transform 0.2s ease-out;

        &.active {
            transform: translateX(0);
        }

        a {
            @include hover-underline($mono-white);
        }

        ul {
            padding: 20px;

            li {
                padding: 10px 2px;

                @include hover-underline(white);
            }
        }
    }


    main {
        grid-area: main;

        padding: 0 20px;
        background-color: $mono-white;
        color: $general-blue-dark;

        a {
            @include hover-underline($general-blue-dark);

            //font-weight: normal;
            //font-size: 12px;
        }

        .jv-container {
            border-radius: 4px;

            .jv-code  {
                padding: 0px;// 10px;
            }
        }
        
    }

    footer {
        grid-area: footer;

        height: $footer-height;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .close-icon {
        font-size: 24px;
        color: #ddd;
        position: absolute;
        visibility: visible;
        top: 5px;
        right: 20px;
        cursor: pointer;

        @media only screen and (min-width: $mobile-breakpoint) {

            display: none;
        }
    }

    .info {
        img {
            margin: 20px;
            border-radius: 48px;
            border: solid 1px;
        }

        // button {
        //     color: $mono-black;
        // }
        
        .tooltip {

            position: relative;
            display: inline-block;

            .tooltiptext {
                width: 140px;
                background-color: $mono-dark;
                color: $mono-glow;
                text-align: center;
                border-radius: 6px;
                padding: 5px;
                position: absolute;
                z-index: 1;
                bottom: 100%;
                left: 50%;
                margin-left: -75px;
                opacity: 0;
                transition: opacity 0.3s;
            }

            .tooltiptext::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: $mono-dark transparent transparent transparent;
            }

            p, &:hover {
                .tooltiptext {
                    opacity: 1;
                }
            }
        }
    }

</style>

<template>

    <div class="dashboard">
        <div class="center-block" v-if="isError"><h3>{{error}}</h3></div>

        <div v-if="!isAuthenticated || !isAuthorized" class="login center-block">
            <img src="../../common/assets/Logo_stack-V-white.svg"/>

            <div v-if="!isAuthenticated">
                <h1>Dashboard</h1>
            </div>
        </div>

        <!-- Authorized user -->
        <div v-else class="grid-container">
            <div class="menu-toggle" v-if="!menuActive" v-on:click="toggleMenu">
                <strong> &#9776;</strong>
            </div>
            <header>
                <div class="title">
                    <img src="../../common/assets/Logo_stack-H-white.svg">
                    <span>Dashboard</span>
                    <img src="favicon-32x32.png">
                </div>
                <div class="button-row">
                    <div class="row-button profile">
                        <img :src="photoUrl" v-on:click="toggleProfileMenu" referrerpolicy="no-referrer">
                    </div>
                </div>
            </header>
            
            <aside :class="{active: menuActive}">
                <div class="close-icon" v-on:click="toggleMenu">
                    <strong>&times;</strong>
                </div>
                <div class="title">
                    <span>Admin Tools</span>
                </div>
                <ul class="list">

                    <a href="#/status"><li class="list-item" :class="{active: route == 'status'}">Status</li></a>
                    <a href="#/user"><li class="list-item" :class="{active: route == 'user'}">User</li></a>
                    <a href="#/access"><li class="list-item" :class="{active: route == 'access'}">‣ Access</li></a>
                    <a href="#/store"><li class="list-item" :class="{active: route == 'store'}">Store</li></a>
                    <a href="#/catalog"><li class="list-item" :class="{active: route == 'catalog'}">‣ Catalog</li></a>
                    <a href="#/products"><li class="list-item" :class="{active: route == 'products'}">‣ Products</li></a>
                    <a href="#/promotions"><li class="list-item" :class="{active: route == 'promotions'}">‣ Promotions</li></a>
                    <a href="#/affiliates"><li class="list-item" :class="{active: route == 'affiliates'}">‣ Affiliates</li></a>
                    <a href="#/accounts"><li class="list-item" :class="{active: route == 'accounts'}">API Accounts</li></a>
                </ul>
            </aside>

            <div class="profile-menu" :class="{active: profileMenuActive}">
                <ul class="list">
                    <li class="list-item" v-on:click="signOut()">Logout</li>
                </ul>
            </div>

            <main class="main">
                <status-page v-if="route=='status'"></status-page>
                <user-page v-else-if="route=='user'" :args="args"></user-page>
                <access-page v-else-if="route=='access'"></access-page>
                <store-page v-else-if="route=='store'"></store-page>
                <catalog-page v-else-if="route=='catalog'"></catalog-page>
                <products-page v-else-if="route=='products'"></products-page>
                <promotions-page v-else-if="route=='promotions'"></promotions-page>
                <affiliates-page v-else-if="route=='affiliates'"></affiliates-page>
                <accounts-page v-else-if="route=='accounts'"></accounts-page>
                <div v-else>
                    <h1>Page not found</h1>
                    <span><a href="javascript:history.back()">Go back and try again...</a></span>
                </div>
            </main>

            <footer>
                <span><strong>{{name}}</strong>: <a :href="'#/user/' + uid"><span>{{uid}}</span></a></span>
                <span><strong>Project</strong>: <a :href="appUrl"><span>{{projectId}} (<em>{{appUrl}}</em>)</span></a></span>
            </footer>
        </div>

        <div v-if="!isLoaded" class="center-block">
            <progress-dots>Loading</progress-dots>
        </div>

        <div id="auth-container"></div>

        <!-- Not authorized -->
        <div v-if="isAuthenticated && !isAuthorized" class="center-block info">
            <h2>{{name}}</h2>
            <img :src="photoUrl" referrerpolicy="no-referrer">
            <!-- <div class="tooltip" >
                <span class="tooltiptext" id="myTooltip">{{copied ? "Copied UID": "Copy to clipboard"}}</span>
                <p><a v-on:click="copyUid()" v-on:mouseout="copyReset()"><em>{{uid}}</em></a></p>
            </div> -->
            <clip-link :value="uid"></clip-link>
            <button v-on:click="signOut()">Logout</button>
            <br/>
        </div> 

        <div v-if="!isAuthenticated || !isAuthorized" class="center-block">
            <img src="favicon-32x32.png">
            <app-notice></app-notice>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';
    
    import DashState from './DashState.vue';

    @Component
    export default class Dashboard extends Vue {

        route = 'access';
        args = null;

        app = null;
        error = null;
        jwt = null;      // user token
        user = null;     // firebase auth
        loaded = false;
        copied = false;

        menuActive = false;
        profileMenuActive = false;

        created() {
            this.app = window.app;
            this.state.environment = this.app.environment; // Expose environment config to app state
        }

        mounted() {
            window.addEventListener('click',this.windowClick);
        }

        destroyed() {
            window.removeEventListener('click', this.windowClick);
        }

        authStatusChanged(jwt, user) {
            if(jwt && user) {
                this.jwt = jwt;
                this.user = user;

            }else {
                this.signIn();
            }
        
            this.loaded = true;
        }

        routeChanged(route, args) {
            console.log("route: " + route);

            this.route = route || 'status';
            this.args = args;
        }

        errorHandler(message, error) {
            this.error = message;
            console.error(error);
        }

        get state() {
            return DashState;
        }

        get isLoaded() {
            return this.app && this.loaded;
        }

        get isError() {
            return this.error !== null;
        }

        get isAuthenticated() {
            return this.jwt !== null;
        }

        get isAuthorized() {
            //return true;
            return this.jwt?.scope?.admin === true;
        }

        get config() {
            return this.state.config;
        }

        get appUrl() {
            return this.config.url.web;
        }

        get projectId() {
            return this.config.firebase;
        }

        get uid() {
            return this.user.uid;
        }

        get name() {
            return this.user.name;
        }
        
        get photoUrl() {
            return this.user?.photoURL;
        }

        signIn() {
            window.app.signIn('#auth-container');
        }

        signOut() {
            window.app.signOut();
        }

        toggleMenu() {
            this.menuActive = !this.menuActive;
        }

        toggleProfileMenu(event) {
            this.profileMenuActive = !this.profileMenuActive;
            event.stopPropagation();
        }

        windowClick() {
            if(this.profileMenuActive) {
                this.profileMenuActive = false;
            }
        }

        copyUid() {
            navigator.clipboard.writeText(this.uid);
            this.copied = true;
        }
        copyReset() {

            setTimeout(()=> {
                // Wait for the menu to fade before reset
                this.copied = false;
            }, 250);
        }
    }
    
</script>
