<script>
import { Component, Vue } from 'vue-property-decorator';
import { reactive } from 'vue';

@Component
class GlobalState extends Vue {
    // Defaults for reactive state
    environment = null;

    created() {
        console.log("Initializing global app state");
    }

    get config() {
        return this.environment?.config;
    }
}

const DashState = reactive(new GlobalState());
export { DashState, DashState as default };

</script>
