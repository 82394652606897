var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dashboard" }, [
    _vm.isError
      ? _c("div", { staticClass: "center-block" }, [
          _c("h3", [_vm._v(_vm._s(_vm.error))]),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isAuthenticated || !_vm.isAuthorized
      ? _c("div", { staticClass: "login center-block" }, [
          _c("img", {
            attrs: {
              src: require("../../common/assets/Logo_stack-V-white.svg"),
            },
          }),
          _vm._v(" "),
          !_vm.isAuthenticated
            ? _c("div", [_c("h1", [_vm._v("Dashboard")])])
            : _vm._e(),
        ])
      : _c("div", { staticClass: "grid-container" }, [
          !_vm.menuActive
            ? _c(
                "div",
                { staticClass: "menu-toggle", on: { click: _vm.toggleMenu } },
                [_c("strong", [_vm._v(" ☰")])]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("header", [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "button-row" }, [
              _c("div", { staticClass: "row-button profile" }, [
                _c("img", {
                  attrs: { src: _vm.photoUrl, referrerpolicy: "no-referrer" },
                  on: { click: _vm.toggleProfileMenu },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("aside", { class: { active: _vm.menuActive } }, [
            _c(
              "div",
              { staticClass: "close-icon", on: { click: _vm.toggleMenu } },
              [_c("strong", [_vm._v("×")])]
            ),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("ul", { staticClass: "list" }, [
              _c("a", { attrs: { href: "#/status" } }, [
                _c(
                  "li",
                  {
                    staticClass: "list-item",
                    class: { active: _vm.route == "status" },
                  },
                  [_vm._v("Status")]
                ),
              ]),
              _vm._v(" "),
              _c("a", { attrs: { href: "#/user" } }, [
                _c(
                  "li",
                  {
                    staticClass: "list-item",
                    class: { active: _vm.route == "user" },
                  },
                  [_vm._v("User")]
                ),
              ]),
              _vm._v(" "),
              _c("a", { attrs: { href: "#/access" } }, [
                _c(
                  "li",
                  {
                    staticClass: "list-item",
                    class: { active: _vm.route == "access" },
                  },
                  [_vm._v("‣ Access")]
                ),
              ]),
              _vm._v(" "),
              _c("a", { attrs: { href: "#/store" } }, [
                _c(
                  "li",
                  {
                    staticClass: "list-item",
                    class: { active: _vm.route == "store" },
                  },
                  [_vm._v("Store")]
                ),
              ]),
              _vm._v(" "),
              _c("a", { attrs: { href: "#/catalog" } }, [
                _c(
                  "li",
                  {
                    staticClass: "list-item",
                    class: { active: _vm.route == "catalog" },
                  },
                  [_vm._v("‣ Catalog")]
                ),
              ]),
              _vm._v(" "),
              _c("a", { attrs: { href: "#/products" } }, [
                _c(
                  "li",
                  {
                    staticClass: "list-item",
                    class: { active: _vm.route == "products" },
                  },
                  [_vm._v("‣ Products")]
                ),
              ]),
              _vm._v(" "),
              _c("a", { attrs: { href: "#/promotions" } }, [
                _c(
                  "li",
                  {
                    staticClass: "list-item",
                    class: { active: _vm.route == "promotions" },
                  },
                  [_vm._v("‣ Promotions")]
                ),
              ]),
              _vm._v(" "),
              _c("a", { attrs: { href: "#/affiliates" } }, [
                _c(
                  "li",
                  {
                    staticClass: "list-item",
                    class: { active: _vm.route == "affiliates" },
                  },
                  [_vm._v("‣ Affiliates")]
                ),
              ]),
              _vm._v(" "),
              _c("a", { attrs: { href: "#/accounts" } }, [
                _c(
                  "li",
                  {
                    staticClass: "list-item",
                    class: { active: _vm.route == "accounts" },
                  },
                  [_vm._v("API Accounts")]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "profile-menu",
              class: { active: _vm.profileMenuActive },
            },
            [
              _c("ul", { staticClass: "list" }, [
                _c(
                  "li",
                  {
                    staticClass: "list-item",
                    on: {
                      click: function ($event) {
                        return _vm.signOut()
                      },
                    },
                  },
                  [_vm._v("Logout")]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "main",
            { staticClass: "main" },
            [
              _vm.route == "status"
                ? _c("status-page")
                : _vm.route == "user"
                ? _c("user-page", { attrs: { args: _vm.args } })
                : _vm.route == "access"
                ? _c("access-page")
                : _vm.route == "store"
                ? _c("store-page")
                : _vm.route == "catalog"
                ? _c("catalog-page")
                : _vm.route == "products"
                ? _c("products-page")
                : _vm.route == "promotions"
                ? _c("promotions-page")
                : _vm.route == "affiliates"
                ? _c("affiliates-page")
                : _vm.route == "accounts"
                ? _c("accounts-page")
                : _c("div", [
                    _c("h1", [_vm._v("Page not found")]),
                    _vm._v(" "),
                    _c("span", [
                      _c(
                        "a",
                        { attrs: { href: "javascript:history.back()" } },
                        [_vm._v("Go back and try again...")]
                      ),
                    ]),
                  ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("footer", [
            _c("span", [
              _c("strong", [_vm._v(_vm._s(_vm.name))]),
              _vm._v(": "),
              _c("a", { attrs: { href: "#/user/" + _vm.uid } }, [
                _c("span", [_vm._v(_vm._s(_vm.uid))]),
              ]),
            ]),
            _vm._v(" "),
            _c("span", [
              _c("strong", [_vm._v("Project")]),
              _vm._v(": "),
              _c("a", { attrs: { href: _vm.appUrl } }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.projectId) + " ("),
                  _c("em", [_vm._v(_vm._s(_vm.appUrl))]),
                  _vm._v(")"),
                ]),
              ]),
            ]),
          ]),
        ]),
    _vm._v(" "),
    !_vm.isLoaded
      ? _c(
          "div",
          { staticClass: "center-block" },
          [_c("progress-dots", [_vm._v("Loading")])],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { attrs: { id: "auth-container" } }),
    _vm._v(" "),
    _vm.isAuthenticated && !_vm.isAuthorized
      ? _c(
          "div",
          { staticClass: "center-block info" },
          [
            _c("h2", [_vm._v(_vm._s(_vm.name))]),
            _vm._v(" "),
            _c("img", {
              attrs: { src: _vm.photoUrl, referrerpolicy: "no-referrer" },
            }),
            _vm._v(" "),
            _c("clip-link", { attrs: { value: _vm.uid } }),
            _vm._v(" "),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.signOut()
                  },
                },
              },
              [_vm._v("Logout")]
            ),
            _vm._v(" "),
            _c("br"),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.isAuthenticated || !_vm.isAuthorized
      ? _c(
          "div",
          { staticClass: "center-block" },
          [
            _c("img", { attrs: { src: "favicon-32x32.png" } }),
            _vm._v(" "),
            _c("app-notice"),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("img", {
        attrs: { src: require("../../common/assets/Logo_stack-H-white.svg") },
      }),
      _vm._v(" "),
      _c("span", [_vm._v("Dashboard")]),
      _vm._v(" "),
      _c("img", { attrs: { src: "favicon-32x32.png" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("Admin Tools")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }