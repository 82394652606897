var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.error
        ? _c("error-notice", { attrs: { error: _vm.error } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "header",
        [
          _c("h1", [_vm._v("User Profile")]),
          _vm._v(" "),
          _c(
            "input-button",
            {
              attrs: { maxlength: 28, placeholder: "Enter uid to search" },
              model: {
                value: _vm.lookup,
                callback: function ($$v) {
                  _vm.lookup = $$v
                },
                expression: "lookup",
              },
            },
            [
              _c("a", { attrs: { href: "#/user/" + _vm.lookup } }, [
                _vm._v("Search"),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("card-grid", [
        _vm.identity
          ? _c(
              "div",
              { staticClass: "card" },
              [
                _c("h2", [_vm._v("Identity")]),
                _vm._v(" "),
                _vm.identity.photoURL
                  ? _c("img", {
                      staticClass: "id-photo",
                      attrs: { src: _vm.identity.photoURL },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _c("strong", [_vm._v("Name")]),
                  _vm._v(": " + _vm._s(_vm.identity?.displayName)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("strong", [_vm._v("UID")]),
                    _vm._v(": "),
                    _c("clip-link", { attrs: { value: _vm.identity?.uid } }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("strong", [_vm._v("Email")]),
                    _vm._v(": "),
                    _c("clip-link", { attrs: { value: _vm.identity?.email } }),
                    _vm._v(" "),
                    _vm.identity?.emailVerified
                      ? _c("span", [_vm._v(" (verified)")])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("strong", [_vm._v("Creation date")]),
                    _vm._v(": "),
                    _c("clip-link", {
                      attrs: { value: _vm.identity?.metadata?.creationTime },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("strong", [_vm._v("Last sign-in")]),
                    _vm._v(": "),
                    _c("clip-link", {
                      attrs: { value: _vm.identity?.metadata?.lastSignInTime },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("strong", [_vm._v("Last refresh")]),
                    _vm._v(": "),
                    _c("clip-link", {
                      attrs: { value: _vm.identity?.metadata?.lastRefreshTime },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.identity
                  ? _c("json-viewer", {
                      attrs: { value: _vm.identity, "expand-depth": 0 },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.profile
          ? _c("div", { staticClass: "card" }, [
              _c("h2", [_vm._v("Status")]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "status",
                  class: { online: _vm.status("online_ios") },
                },
                [_c("strong", [_vm._v("iOS")]), _vm._v(": ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "status",
                  class: { online: _vm.status("online_android") },
                },
                [_c("strong", [_vm._v("Android")]), _vm._v(": ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "status",
                  class: { online: _vm.status("online_web") },
                },
                [_c("strong", [_vm._v("Web")]), _vm._v(": ")]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.profile
          ? _c("div", { staticClass: "card" }, [
              _c("h2", [_vm._v("Last Login")]),
              _vm._v(" "),
              _vm.profile?.lastlogin_ios
                ? _c("div", [
                    _c("strong", [_vm._v("iOS")]),
                    _vm._v(": " + _vm._s(_vm.profile?.lastlogin_ios)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile?.lastlogin_android_ab
                ? _c("div", [
                    _c("strong", [_vm._v("Android AB")]),
                    _vm._v(": " + _vm._s(_vm.profile?.lastlogin_android_ab)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile?.lastlogin_android_bc
                ? _c("div", [
                    _c("strong", [_vm._v("Android BC")]),
                    _vm._v(": " + _vm._s(_vm.profile?.lastlogin_android_bc)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile?.lastlogin_android_mb
                ? _c("div", [
                    _c("strong", [_vm._v("Android MB")]),
                    _vm._v(": " + _vm._s(_vm.profile?.lastlogin_android_mb)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile?.lastlogin_android_nb
                ? _c("div", [
                    _c("strong", [_vm._v("Android NB")]),
                    _vm._v(": " + _vm._s(_vm.profile?.lastlogin_android_nb)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile?.lastlogin_android_ns
                ? _c("div", [
                    _c("strong", [_vm._v("Android NS")]),
                    _vm._v(": " + _vm._s(_vm.profile?.lastlogin_android_ns)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile?.lastlogin_android_on
                ? _c("div", [
                    _c("strong", [_vm._v("Android ON")]),
                    _vm._v(": " + _vm._s(_vm.profile?.lastlogin_android_on)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile?.lastlogin_android_qb
                ? _c("div", [
                    _c("strong", [_vm._v("Android QB")]),
                    _vm._v(": " + _vm._s(_vm.profile?.lastlogin_android_qb)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile?.lastlogin_android_sk
                ? _c("div", [
                    _c("strong", [_vm._v("Android SK")]),
                    _vm._v(": " + _vm._s(_vm.profile?.lastlogin_android_sk)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile?.lastlogin_android_yt
                ? _c("div", [
                    _c("strong", [_vm._v("Android YT")]),
                    _vm._v(": " + _vm._s(_vm.profile?.lastlogin_android_yt)),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.profile
          ? _c("div", { staticClass: "card" }, [
              _c("h2", [_vm._v("Purchaser ID")]),
              _vm._v(" "),
              _vm.profile?.purchaser_id_ios
                ? _c("div", [
                    _c("strong", [_vm._v("iOS")]),
                    _vm._v(": " + _vm._s(_vm.profile?.purchaser_id_ios)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile?.purchaser_id_android_ab
                ? _c("div", [
                    _c("strong", [_vm._v("Android AB")]),
                    _vm._v(": " + _vm._s(_vm.profile?.purchaser_id_android_ab)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile?.purchaser_id_android_bc
                ? _c("div", [
                    _c("strong", [_vm._v("Android BC")]),
                    _vm._v(": " + _vm._s(_vm.profile?.purchaser_id_android_bc)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile?.purchaser_id_android_mb
                ? _c("div", [
                    _c("strong", [_vm._v("Android MB")]),
                    _vm._v(": " + _vm._s(_vm.profile?.purchaser_id_android_mb)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile?.purchaser_id_android_nb
                ? _c("div", [
                    _c("strong", [_vm._v("Android NB")]),
                    _vm._v(": " + _vm._s(_vm.profile?.purchaser_id_android_nb)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile?.purchaser_id_android_ns
                ? _c("div", [
                    _c("strong", [_vm._v("Android NS")]),
                    _vm._v(": " + _vm._s(_vm.profile?.purchaser_id_android_ns)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile?.purchaser_id_android_on
                ? _c("div", [
                    _c("strong", [_vm._v("Android ON")]),
                    _vm._v(": " + _vm._s(_vm.profile?.purchaser_id_android_on)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile?.purchaser_id_android_qb
                ? _c("div", [
                    _c("strong", [_vm._v("Android QB")]),
                    _vm._v(": " + _vm._s(_vm.profile?.purchaser_id_android_qb)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile?.purchaser_id_android_sk
                ? _c("div", [
                    _c("strong", [_vm._v("Android SK")]),
                    _vm._v(": " + _vm._s(_vm.profile?.purchaser_id_android_sk)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.profile?.purchaser_id_android_yt
                ? _c("div", [
                    _c("strong", [_vm._v("Android YT")]),
                    _vm._v(": " + _vm._s(_vm.profile?.purchaser_id_android_yt)),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.profile
          ? _c(
              "div",
              { staticClass: "card" },
              [
                _c("h2", [_vm._v("Profile")]),
                _vm._v(" "),
                _c("div", [_vm._v("/users/" + _vm._s(_vm.uid))]),
                _vm._v(" "),
                _vm.profile
                  ? _c("json-viewer", {
                      attrs: { value: _vm.profile, "expand-depth": 0 },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.summary
          ? _c(
              "div",
              { staticClass: "card" },
              [
                _c("h2", [_vm._v("Summary")]),
                _vm._v(" "),
                _c("div", [_vm._v("User account summary")]),
                _vm._v(" "),
                _vm.summary
                  ? _c("json-viewer", {
                      attrs: { value: _vm.summary, "expand-depth": 0 },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.purchases
          ? _c(
              "div",
              { staticClass: "card" },
              [
                _c("h2", [_vm._v("Purchases")]),
                _vm._v(" "),
                _c("div", [_vm._v("User purchases")]),
                _vm._v(" "),
                _vm.purchases
                  ? _c("json-viewer", {
                      attrs: { value: _vm.purchases, "expand-depth": 0 },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.uid === null ? _c("h3", [_vm._v("Search by UID")]) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }