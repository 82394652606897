import DataAccessLayer from './DataAccessLayer';
import CatalogHostedResources from './CatalogHostedResources';
import CatalogProvider from './CatalogProvider';
import CatalogBuilder from './CatalogBuilder';

export default class CatalogHostedProvider extends CatalogProvider {

    constructor(product, urls) {
        super(product);

        this.urls = urls || {};
        this.http = new DataAccessLayer();
        this.builder = new CatalogBuilder(this);
        this.builder.includeMetadata = false;
    }

    async getRegions() { // Array of region id's
        let regions = await this.http.getJson(this.server('boundaries') + CatalogHostedResources.AllRegions());
        return regions.provinces; // Remove provinces
    }

    watchCatalog(notify) {
        this.builder.getRegions().then((regions) => {
            return this.processRegions(regions, notify);
        }).catch((error) => {
            throw error;
        });
    }

    watchRegions(regions, notify) {
        this.processRegions(regions, notify);
    }

    watchRegion(id, notify) {
        this.builder.getRegion(id).then((region) => {
            this.catalog[id] = region;
            return notify(id, this.catalog[id]);
        }).catch((error) => {
            throw error;
        });
    }

    server(type) {
        return this.urls[type] || '';
    }

    async getRegionMetadata(region) {
        return this.http.getJson(this.server('boundaries') + CatalogHostedResources.RegionMetadata(region));
    }

    async getSubscriptionMetadata(region, subscription) {
        try {
            return await this.http.getJson(this.server('boundaries') + CatalogHostedResources.SubscriptionMetadata(region, subscription));
        }catch(error) {
            return null; // May not exist; ignore failure
        }
    }

    async getSubscriptionLayerMetadata(region, subscription, layer) {
        try {
            return await this.http.getJson(this.server('boundaries') + CatalogHostedResources.SubscriptionLayerMetadata(region, subscription, layer));
        }catch(error) {
            return null; // May not exist; ignore failure
        }
    }

    

    async getSubscriptionLayerBoundaryGeoJson(region, subscription, layer) {
        try {
            return await this.http.getJson(this.server('boundaries') + CatalogHostedResources.SubscriptionLayerBoundaryGeoJson(region, subscription, layer));
        }catch(error) {
            return null; // May not exist; ignore failure
        }
    }

    async getResourceMetadata(region, resource) {
        try {
            return await this.http.getJson(this.server('boundaries') + CatalogHostedResources.ResourceMetadata(region, resource));
        }catch(error) {
            return null; // May not exist; ignore failure
        }
    }

    async getResourceLayerMetadata(region, resource, layer) {
        try {
            return await this.http.getJson(this.server('boundaries') + CatalogHostedResources.ResourceLayerMetadata(region, resource, layer));
        }catch(error) {
            return null; // May not exist; ignore failure
        }
    }

    async getResourceLayerBoundarydata(region, resource, layer) {
        try {
            return await this.http.getJson(this.server('boundaries') + CatalogHostedResources.ResourceLayerBoundarydata(region, resource, layer));
        }catch(error) {
            return null; // May not exist; ignore failure
        }
    }


    async getResourceLayerBoundaryGeoJson(region, resource, layer) {
        try {
            return await this.http.getJson(this.server('boundaries') + CatalogHostedResources.ResourceLayerBoundaryGeoJson(region, resource, layer));
        }catch(error) {
            return null; // May not exist; ignore failure
        }
    }

    async getResourceLayerMapMetadata(region, resource, layer, map) {
        try {
            // There isn't stored metadata for the map level at the moment
            if(resource === 'purchasable_layers' && layer === 'county') {
                return this.builder.getCountyMapMetadata(region, map);
            }
            return null;

        }catch(error) {
            return null; // May not exist; ignore failure
        }
    }

    async getResourceLayerProductMetadata(region, resource, layer, map, product) {
        try {
            if(resource === 'purchasable_layers' && layer === 'county') {
                return await this.getCountyProductMetadata(region, product)
            }
            return null;
        }catch(error) {
            return null; // May not exist; ignore failure
        }
    }




    async getCountyLayerMetadata(region) {
        try {
            // console.log("fetch: " + CatalogHostedResources.CountyMetadata(region));
            return await this.http.getJson(this.server('tiles') + CatalogHostedResources.CountyMetadata(region));
        }catch(error) {
            return null; // May not exist; ignore failure
        }
    }
    async getCountyLayerBoundarydata(region) {
        return this.getResourceLayerBoundarydata(region, 'purchasable_layers', 'county')
    }

    async getCountyLayerBoundaryGeoJson(region, county) {
        try {
            return await this.http.getJson(this.server('tiles') + CatalogHostedResources.CountyBoundaryGeoJson(region, county));
        }catch(error) {
            return null; // May not exist; ignore failure
        }
    }

    async getCountyProductMetadata(region, county) {
        try {
            // console.log("fetch: " + CatalogHostedResources.CountyProductMetadata(region, county));
            return await this.http.getJson(this.server('tiles') + CatalogHostedResources.CountyProductMetadata(region, county));
        }catch(error) {
            return null; // May not exist; ignore failure
        }
    }

    getProductId(sku) {
        let product = sku.slice(0, -5);     // Remove date
        return product.replace(/\W+$/, ""); // Trim non-alphanumeric
    }
    
    // splitMapProduct(sku) {
    //     let split = sku.lastIndexOf('_');
    //     let version = sku.slice(split + 1);
    //     let product = sku.slice(0, split);     // Remove date
    //     return {
    //         product: product.replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""), // Trim non-alphanumeric
    //         version: version

    //     };
    // }   
}